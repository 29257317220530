<template>
  <div>
    <div v-loading:fullscreen="!configLoaded || loading" class="vue-app u-height-100">
      <div v-if="configLoaded && translationsLoaded" class="content">
        <div v-if="showHeader" class="top">
          <div class="o-container">
            <div class="o-grid">
              <div class="o-grid__col">
                <span v-if="isLoggedIn">
                  <a href="javascript:void(0)" class="top__link" @click="signOut">{{ $t('menu_signout') }}</a>
                  <el-popover ref="myAccountMenu" placement="bottom" trigger="hover">
                    <router-link :to="{ name: 'MyData' }" class="popover__item">{{ $t('menu_my_data') }}</router-link>
                    <router-link v-if="isMain" :to="{ name: 'MyAccounts' }" class="popover__item">{{ $t('menu_my_accounts') }}</router-link>
                    <router-link :to="{ name: 'MyAnalyzes' }" class="popover__item">{{ $t('menu_my_analyses') }}</router-link>
                    <router-link v-if="isAllowedToOrder" :to="{ name: 'MyOrders' }" class="popover__item">{{ $t('menu_my_orders') }}</router-link>
                  </el-popover>
                  <span v-popover:myAccountMenu class="top__link">{{ $t('menu_my_account') }}</span>
                  <router-link v-if="isAllowedToOrder" :to="{ name: 'Order' }">
                    <el-button type="primary">{{ $t('cta_order') }}</el-button>
                  </router-link>
                </span>
                <span v-else>
                  <router-link :to="{ name: 'Login' }" class="top__link">{{ $t('menu_signin') }}</router-link>
                  <router-link :to="{ name: 'Register' }" class="top__link">{{ $t('menu_register') }}</router-link>
                </span>
                <span class="languages">
                  <a
                    v-for="(item, index) in languages"
                    :key="index"
                    :href="`?lang=${item.code}`"
                    :class="{ 'language--active': item.code === languageCode }"
                    class="language"
                    ><!--
                    -->{{ item.code
                    }}<!--
                  --></a>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="header u-pv-20">
          <div class="o-container">
            <div class="o-grid">
              <div class="o-grid__col u-3/12 u-mv-5 u-pl-5">
                <router-link :to="{ name: 'Home' }">
                  <img src="@a/images/logo.svg" class="logo" alt="Disc Boulevard" />
                </router-link>
              </div>
              <div class="o-grid__col u-9/12"></div>
            </div>
          </div>
        </div>
        <div class="u-bgcolor--secondary u-pv-30">
          <div class="o-container">
            <div class="o-grid">
              <div class="o-grid__col">
                <router-view />
              </div>
            </div>
          </div>
        </div>
      </div>
      <google-tag-manager :gtm-id="$configValue('GTM_ID')" />
    </div>
  </div>
</template>

<script>
/* eslint-disabled */
import api from '../api/'
import { Button, Popover } from 'element-ui'
import GoogleTagManager from '../components/GoogleTagManager'

export default {
  components: {
    'el-popover': Popover,
    'el-button': Button,
    'google-tag-manager': GoogleTagManager
  },
  data() {
    return {
      loading: false,
      translationsLoaded: false
    }
  },
  computed: {
    configLoaded() {
      return this.$store.getters['runtime-config/isInitialLoadCompleted']
    },
    getUser() {
      return this.$store.getters['account/getUser']
    },
    isMain() {
      return this.$store.getters['account/isMain']
    },
    isAllowedToOrder() {
      return this.$store.getters['account/isAllowedToOrder']
    },
    isLoggedIn() {
      return this.getUser !== undefined
    },
    showHeader() {
      return this.$route.meta.header !== false
    },
    languageCode() {
      return localStorage.getItem('activeLangCode') ?? 'nl'
    },
    languages() {
      return this.$store.getters['runtime-config/getConfigValue']('LANGUAGES')
    },
    profileTestLanguageCode() {
      return this.$store.getters['profileTest/getLanguageCode']
    }
  },
  watch: {
    configLoaded(loaded) {
      if (loaded) {
        this.loading = true
        if (window.location.pathname === '/profiel-test/' || window.location.pathname.startsWith('/download-report')) {
          //skip get active user HRM-193
          this.loading = false
        } else {
          this.$store
            .dispatch('account/getActiveUser')
            .then(() => {
              this.handlePublicRoute()
              this.loading = false
            })
            .catch(() => {
              this.loading = false
              if (this.$route.name !== 'Login') {
                this.$router.push({ name: 'Login' })
              }
            })
        }
      }
    },
    $route: {
      handler(to, from) {
        if (from && this.configLoaded && !this.loading) {
          this.handlePublicRoute()
        }
      }
    },
    isLoggedIn: {
      handler(val) {
        if (!val && this.$route.name !== 'Login') {
          this.$router.push({ name: 'Login' })
        }
      }
    },
    profileTestLanguageCode: {
      handler(val) {
        localStorage.setItem('activeLangCode', val)
        this.loadTranslations(val)
      }
    }
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get('lang')) {
      localStorage.setItem('activeLangCode', urlParams.get('lang'))
      this.loadTranslations(urlParams.get('lang'))
    } else {
      this.loadTranslations()
    }
  },
  methods: {
    loadTranslations(langCode) {
      this.loading = true
      const languageCode = langCode ?? this.languageCode
      api()
        .get(`/reseller/translations/${languageCode}`)
        .then(({ data: translations }) => {
          const messages = translations.customData
          this.$i18n.setLocaleMessage('nl', messages)
          this.translationsLoaded = true
          this.loading = false
        })
        .catch(e => {
          this.$notify.error({
            title: this.$t('errors_title'),
            message: this.$t(e.message.toLowerCase())
          })
          this.loading = false
        })
    },
    handlePublicRoute() {
      if (this.isLoggedIn) {
        if (this.$route.meta.public && this.$route.name !== 'Home') {
          this.$router.push({ name: 'Home' })
        }
      } else {
        if (!this.$route.meta.public && this.$route.name !== 'Login') {
          this.$router.push({ name: 'Login' })
        }
      }
    },
    signOut() {
      this.$store.dispatch('account/signout').then(() => {
        this.$notify.success({
          title: this.$t('success_title'),
          message: this.$t('success_signout')
        })
      })
    }
  }
}
</script>
<style lang="scss">
.top {
  border-bottom: $border__base--default;
  height: 60px;
  text-align: right;
  &__link {
    padding: 0 10px;
    margin: 0;
    line-height: 60px;
    color: $color--tertiary;
    border-right: $border__base--default;
    &:last-of-type {
      border-right: 0 none;
    }
  }
  .languages {
    .language {
      border-right: $border__base--default;
      padding: 0 5px;
      margin: 0;
      &:last-of-type {
        border-right: 0 none;
      }
      &--active {
        text-decoration: underline;
      }
    }
  }
}
.popover__item {
  line-height: 28px;
  display: block;
}
.header {
  height: 106px;
  .logo {
    width: 170px;
  }
}
@media only screen and (max-width: 640px) {
  .top {
    font-size: 14px;
    white-space: nowrap;
    &__link {
      padding: 0 5px;
    }
    .el-button {
      padding: 12px;
      font-size: 14px;
    }
  }
}
</style>
